import { render, staticRenderFns } from "./RoleMenu.vue?vue&type=template&id=a355ac86"
import script from "./RoleMenu.vue?vue&type=script&lang=js"
export * from "./RoleMenu.vue?vue&type=script&lang=js"
import style0 from "./RoleMenu.vue?vue&type=style&index=0&id=a355ac86&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "RoleMenu.vue"
export default component.exports